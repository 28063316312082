<template>
  <div class="shipments-page">
    <div class="md:flex md:flex-wrap grid grid-cols-2 gap-2 items-center whitespace-nowrap md:mb-6 mb-3">
      <download-button :selected="selected"></download-button>
      <print-label-button :selected="selected"></print-label-button>
      <export-button v-if="localStorage.getItem('multipleWarehouses') === 'true'" />
    </div>
    <shipblu-table
      :sst="true"
      multiple
      orders
      v-model="selected"
      pagination
      :max-items="maximumItems"
      @search="handleSearch"
      @keyup.enter.native="handleSearch"
      search
      :data="cashRequests"
      :tableLoader="tableLoader"
    >
      <template slot="header">
        <div class="lg:w-auto w-full md:mt-0 lg:flex grid grid-cols-3 gap-2 items-center">
          <table-filter icon="BarChart2Icon" filterLabel="Status" :filters="filters" :statusFilters="statusFilters" @changeFilter="changeFilter" @filterValue="filterValue = $event"/>
        </div>
      </template>
      <template slot="thead">
        <shipblu-th>{{$t('Tracking No.')}}</shipblu-th>
        <shipblu-th>{{$t('Merchant')}}</shipblu-th>
        <shipblu-th>{{$t('Customer')}}</shipblu-th>
        <shipblu-th>{{$t('City')}}</shipblu-th>
        <shipblu-th>{{$t('Zone')}}</shipblu-th>
        <shipblu-th>{{$t('Address')}}</shipblu-th>
        <shipblu-th>{{$t('Status')}}</shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" :color="`${getStyle(data[indextr])}`"
          :to="{ name: `${$store.state.AppActiveUser.userRole}-cash-collections`, params: { type: 'cash-collections', orderID: data[indextr].id }}">
          <shipblu-td class="sm:hidden block col-span-2 p-0 order-3">
            <!-- line -->
            <p style="background-color: #DCDCDC;height: 1px;"></p>
          </shipblu-td>

          <shipblu-td :data="data[indextr].tracking_number" class="text-left sm:order-3 order-25 sm:pointer-events-none">
            <div class="flex sm:static absolute top-3 gap-2 items-start justify-start transform-50" v-on:click.stop>
              <p class="sm:text-base text-lg flex gap-1 items-start justify-center">
                {{ data[indextr].tracking_number }}
                <span @click="copied(data[indextr].tracking_number)" class="material-icons-round text-grey hover:text-primary text-xl cursor-pointer sm:hidden block">content_copy</span>
              </p>
            </div>
          </shipblu-td>

          <shipblu-td class="order-4" :data="data[indextr].merchant">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Merchant')}}</p>
            <p class="sm:text-base text-sm">{{ data[indextr].merchant.name }}</p>
          </shipblu-td>

          <shipblu-td class="order-5" :data="data[indextr].customerInfo">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Customer')}}</p>
            <p class="sm:text-base text-sm">{{ data[indextr].customer.full_name }}</p>
          </shipblu-td>

          <shipblu-td class="order-6" :data="data[indextr].customerCity">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('City')}}</p>
            <p class="sm:text-base text-sm">{{ $t(data[indextr].customer.address.zone.city.name) }}</p>
          </shipblu-td>
          <shipblu-td class="order-7" :data="data[indextr].customerZone">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Zone')}}</p>
            <p class="sm:text-base text-sm">{{ $t(data[indextr].customer.address.zone.name) }}</p>
          </shipblu-td>

          <shipblu-td class="order-8" :data="data[indextr].location">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Address')}}</p>
            <p class="sm:text-base text-sm">{{ data[indextr].customer.address.line_1 }} / {{ data[indextr].customer.address.line_2 }}</p>
          </shipblu-td>

          <shipblu-td class="order-9" :data="data[indextr].status" style="white-space: nowrap;">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Status')}}</p>
            <div class="flex" style="width: fit-content;">
              <span class="capitalize border border-solid rounded-4xl px-5 py-1.5 leading-none sm:text-sm text-xs text-white" :style="`background-color: ${getStyle(data[indextr])}`">{{ $t(getStatusLabel(data[indextr])) }}</span>
            </div>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-cursor-pagination :maximumItems="maximumItems" :paginationObj="paginationObj" @changePageSize="changePageSize" @changePageNumber="changePageNumber($event)" />
    <print :selected="selected"></print>
    <block-customer :blockCustomerModal="blockCustomerModal" @blockCustomerModal="blockCustomerModal = $event" :data="customer" @loadShipments="loadCashCollection"></block-customer>
  </div>
</template>

<script>
import Shipment from '../merchant/components/Shipment.vue'
import ExportButton from './components/ExportButton.vue'
import PrintLabelButton from './components/PrintLabelButton.vue'
import Print from '../merchant/components/PrintLabels.vue'
import i18nData from '../../i18n/i18nData.js'
import common  from '@/assets/utils/common'
import {sendRequest} from '../../http/axios/requestHelper.js'
import BlockCustomer from '../admin/components/BlockCustomer.vue'
import ShipbluTable from '../../layouts/components/NewShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import TableFilter from '../merchant/components/TableFilter.vue'
import ShipbluCursorPagination from '../../layouts/components/ShipbluCursorPagination.vue'
import DownloadButton from '../admin/components/DownloadButton.vue'

export default {
  data () {
    return {
      localStorage,
      selected: [],
      cashRequests: [],
      type: '',
      cashCollectionModal: false,
      statusFilters: [
        {
          name: 'All',
          value: 'all',
          color: '#118C4F'
        },
        {
          name: 'created',
          value: 'created'
        },
        {
          name: 'Out For Collection',
          value: 'out_for_collection'
        },
        {
          name: 'Collected',
          value: 'collected'
        },
        {
          name: 'Failed to Collect',
          value: 'collection_attempted'
        },
        
        {
          name: 'Cancelled',
          value: 'cancelled'
        }
      ],
      maximumItems: localStorage.getItem('maxItems') ? Number(localStorage.getItem('maxItems')) : process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      filters: [],
      searchVal: '',
      paginationObj: {
        currentPage: 1,
        next: null,
        previous: null
      },
      cashData: {},
      cashStatus: '',
      file_URl: '',
      searchInProgress: false,
      searchedValue: ' ',
      blockCustomerModal: false,
      customer: {},
      tableLoader: false
    }
  },
  watch: {
    '$route.query.filter' () {
      if (!this.$route.query.filter) {
        this.filters = []
        this.loadCashCollection()
      }
    },
    '$route.params.warehouseID' () {
      this.searchVal = ''
      this.maximumItems = process.env.VUE_APP_MAXIMUM_ITEMS_TABLE
      this.loadCashCollection()
    }
  },
  methods: {
    changePageNumber (val) {
      this.paginationObj.currentPage = val === 'next' ? this.paginationObj.currentPage + 1 : this.paginationObj.currentPage - 1
      this.loadCashCollection(this.paginationObj[val])
    },
    changePageSize (val) {
      localStorage.setItem('maxItems', val)
      this.maximumItems = val
      this.loadCashCollection()
    },
    blockCustomer (data) {
      this.customer = data
      this.blockCustomerModal = true
    },
    handleSearch (search) {
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadCashCollectionsSearch)
    },
    loadCashCollectionsSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      this.loadCashCollection()
    },
    changeFilter () {
      const filtersTemp = this.filters
      this.filters = []
      filtersTemp.forEach(item => {
        this.filters.push(item)
      })
      this.filters.includes(this.filterValue) ? this.filters.splice(this.filters.indexOf(this.filterValue), 1) : this.filters.push(this.filterValue)
      if (this.filterValue === 'all') {
        this.filters = []
      }
      this.$router.push({
        name: `${this.$store.state.AppActiveUser.userRole}-cash-collections-view`,
        query: {
          filter: this.filters
        }
      }).catch(() => {})
      this.loadCashCollection()
    },
    loadCashCollection (cursorVal) {
      if (!cursorVal) this.paginationObj.currentPage = 1
      this.searchInProgress = true
      this.tableLoader = true
      const query = `?status=${this.filters}&cursor=${cursorVal ? cursorVal : ''}&limit=${this.maximumItems}&search=${this.searchVal}&warehouse_code=${this.$route.params.warehouseID ? this.$route.params.warehouseID : ''}`
      sendRequest(true, false, this, `api/v1/cash-collections/${query}`, 'get', null, true,
        (response) => {
          this.cashRequests = response.data.results
          this.tableLoader = false
          this.paginationObj.next = response.data.next && new URL(response.data.next).searchParams.get('cursor')
          this.paginationObj.previous = response.data.previous && new URL(response.data.previous).searchParams.get('cursor')
          this.searchedValue = this.searchVal
        }
      )
      this.searchInProgress = false
    },
    getOrderStatusColor (order) {
      return common.getOrderStatusColor(this.getStatusLabel(order))
    },
    getStatusLabel (order) {
      return common.getStatusLabel(order, this.$store.state.AppActiveUser.userRole)
    },
    getStyle (data) {
      return common.getOrderStatusColor(data.status)
    },
    viewCashCollection (type, data) {
      this.$router.push({
        name: `${this.$store.state.AppActiveUser.userRole}-cash-collections`,
        params: {
          type: 'cash-collections',
          orderID: data.id,
          warehouseID: this.$store.state.AppActiveUser.userRole === 'head-of-fleet' ? this.$route.params.warehouseID : ''
        }
      }).catch(() => {})
    },
    updateCashStatus (data, sentence, status) {
      this.cashData = data
      this.cashStatus = status
      this.$vs.dialog({
        color: 'danger',
        title: 'Confirm',
        text: `Are you sure you want to ${sentence} ?`,
        acceptText: 'Yes',
        accept: this.confirmUpdateCashStatus
      })
    },
    confirmUpdateCashStatus (data, status) {
      const cashCollection = [
        {
          status: status ? status : this.cashStatus,
          cash_collection_request: data ? data.id : this.cashData.id
        }
      ]
      sendRequest(false, false, this, 'api/v1/tracking/cash-collections/', 'post', cashCollection, true,
        () => {
          this.$vs.notify({
            color:'success',
            title:i18nData[this.$i18n.locale]['Success'],
            text:i18nData[this.$i18n.locale]['Cash Collection'] + i18nData[this.$i18n.locale][' has been updated successfully!'],
            position: 'top-center'
          })
          this.loadCashCollection()
        }
      )
    },
    getInvoice (data) {
      sendRequest(false, false, this, `api/v1/billing/merchants/${data.merchant.id}/invoices/?invoicecashcollection__order=${data.id}`, 'get', null, true,
        (response) => {
          if (response.data.results.length > 0) {
            this.downloadInvoice(response.data.results[0].id)
          } else {
            this.$vs.notify({
              color:'warning',
              title:i18nData[this.$i18n.locale]['Warning'],
              text:i18nData[this.$i18n.locale]['Invoice isn\'t ready'],
              position: 'top-center'
            })
          }
        }
      )
    },
    downloadInvoice (invoiceId) {
      sendRequest(false, false, this, `api/v1/billing/invoices/${invoiceId}/pdf/`, 'get', null, true,
        (response) => {
          this.file_URl = response.data.file_url
          window.open(this.file_URl, '_blank')
        }
      )
    },
    async copied (data) {
      try {
        await navigator.clipboard.writeText(data)
      } catch ($e) {
        this.$vs.notify({
          color:'dark',
          title:i18nData[this.$i18n.locale]['cannot copy!'],
          position: 'bottom-center'
        })
      }
    }
  },
  components: {
    ExportButton,
    PrintLabelButton,
    Print,
    Shipment,
    BlockCustomer,
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    TableFilter,
    ShipbluCursorPagination,
    DownloadButton
  },
  mounted () {
    this.$emit('setAppClasses', 'shipment-page')
  },
  created () {
    this.filters = this.$route.query.filter ? typeof (this.$route.query.filter) === 'string' ? Array(this.$route.query.filter) : this.$route.query.filter : []
    this.loadCashCollection()
  }
}
</script>

<style>
#order-label-print{
  display: none;
  visibility: hidden;
}
.con-pagination-table{
  display: none;
}
</style>
